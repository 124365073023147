import axios from '@/utils/axios';
const baseURL = '/api'
// 信息公告

// 列表
export const getMessageList = (params) => {
  return axios({
    baseURL,
    url: '/app/system/notice/page',
    method: 'get',
    params
  });
};
// 详情
export const getMessageDetail = (params) => {
  return axios({
    baseURL,
    url: '/app/system/notice/detail',
    method: 'get',
    params
  });
};
